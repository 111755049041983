import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import BrowserRouter
import { theme } from "../utils/theme";

import "./App.css";

const BlockedPage = lazy(() => import("../pages/BlockedPage/BlockedPage"));

let darkTheme = createTheme(theme);
const _darkTheme = responsiveFontSizes(darkTheme);

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ThemeProvider theme={_darkTheme}>
        <Router> {/* Wrap Routes component within Router */}
          <Routes>
            {/* <Route path="/" element={<BlockedPage />} /> */}
            <Route
              path="*"
              element={
                <BlockedPage
                  heading="Temporarily Unavailable"
                  text={
                    <>
                      Glitter Bridge services are temporarily unavailable. <br />
                      If you need assistance, contact support@glitter.finance. <br />
                      <br />
                      Follow us on our social media channels for updates.
                    </>
                  }
                />
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;